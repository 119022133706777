import React, {useEffect, useState} from 'react';
import Header from "./components/header";
import {BrowserRouter, Routes,Link,  Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { QueryClient, QueryClientProvider} from "react-query";
import CookieConsent from "react-cookie-consent";

import './index.scss';
import Home from "./components/home";
import Category from "./components/category";
import Contact from "./components/Contact";
import Post from "./components/post";
import Error from "./components/error";
import Article from "./components/article";
import axios from "axios";
function App() {
    const location = useLocation();
    const queryClient = new QueryClient();
    const [ allProducts, setAllProducts] = useState([]);
    const [ fetching, setFetching] = useState(true)

    const getResults = async () => {
        const portfolio = await axios('https://nadia-taufe.com/m/index.php/wp-json/wp/v2/posts?per_page=150');
        setAllProducts(portfolio.data);
    }

    useEffect( () => {
        getResults();
    },[fetching]);

    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <div className='body-container'>
                    <Header products={allProducts}/>
                    <CookieConsent
                        location="bottom"
                        buttonText="Einverstanden"
                        cookieName="Cookies"
                        style={{ background: "#C77A9A" }}
                        buttonStyle={{background:"#fcfce8", color: "#C77A9A", fontSize: "13px" }}
                        buttonClasses = "btn"

                        expires={150}
                    >
                        Diese Internetseite verwendet Cookies <Link className="btn mx-md-4" to="datenschutz">Mehr erfahren </Link>

                    </CookieConsent>
                    <TransitionGroup className="transition-group">
                        <CSSTransition
                            key={location.key}
                            classNames="transition-element fade"
                            transitionAppear={false}
                            transitionEnter={true}
                            transitionLeave={true}
                            timeout={{
                                appear: 600,
                                enter: 600,
                                exit: 600,
                            }}
                            unmountOnExit={false}
                        >
                            <Routes location={location} className='container'>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/kontakt" element={<Contact children={allProducts}/>}/>
                                <Route path="/:name" element={<Article/>}/>
                                <Route path=":kategorie/:category" element={<Category/>}/>
                                <Route path=":kategorie/:category/:name" element={<Post/>}/>
                                <Route path="/404" element={<Error/>}/>
                            </Routes>
                        </CSSTransition>
                    </TransitionGroup>
                </div>

            </QueryClientProvider>

        </React.StrictMode>
    )
}


export default App;
